
import { Nullable } from '@/types'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const PlaceTypesStore = namespace('PlaceTypesStore')
// import { PlaceTypesModel } from '@/core/models/PlaceTypesModel'
import CategoryService from '../CategoryService'
import { CategoryModel } from '../models/CategoryModel'
import BillingPlanService from '../BillingPlanService'
import { BillingPlanModel } from '../models/BillingPlanModel'
import { toLocalCurrency } from '@/utils/currencyFormatter'

import Overlay from '@/components/Overlay.vue'
import Modal from '@/components/Modal.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseSelectNew from '@/components/forms/BaseSelectNew/BaseSelect.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Spinner from '@/components/Spinner.vue'

interface CategoryInterface {
  id: number
  name: string
  description: string
  placeTypes: string[]
  hasBillingPlan: boolean
  minPrice: number
}

interface ItemInterface {
  id: number
  name: string
}

@Component({
  name: 'AddTariffPlan',
  components: {
    Overlay,
    Modal,
    BaseIcon,
    BaseButton,
    BaseSelectNew,
    FormGroup,
    Spinner,
  },
})
export default class extends Vue {
  @Prop({ default: false })
  current!: boolean

  loading = false
  processing = false
  categories: CategoryModel[] = []
  billingPlans: BillingPlanModel[] = []
  currentBillingPlans: BillingPlanModel[] = []
  categoryId: Nullable<number> = null
  maxLocations: Nullable<number> = null
  billingPeriod: Nullable<number> = null
  showErrorModal = false
  errorMessage = ''

  toLocalCurrency = toLocalCurrency

  @PlaceTypesStore.Getter
  public placeTypesList!: any[]

  @PlaceTypesStore.Action
  private getPlaceTypes!: (all: boolean) => Promise<any[]>

  get fullCategories(): CategoryInterface[] {
    return this.categories.map((category) => ({
      id: category.id,
      name: category.name,
      description: category.description,
      placeTypes: category.place_types
        .map(
          (id) => this.placeTypesList.find((item) => item.id === id)?.name || ''
        )
        .filter((item) => item),
      hasBillingPlan:
        this.billingPlans.filter((item) => item.category === category.id)
          .length > 0,
      isFree: !this.billingPlans.some(
        (item) => item.category === category.id && item.price
      ),
      minPrice: (() => {
        const prices = this.billingPlans
          .filter((item) => item.category === category.id)
          .map((item) => item.price || 0)
        return prices.length ? Math.min(...prices) : 0
      })(),
    }))
  }

  get maxLocationsList(): ItemInterface[] {
    return [
      ...new Set(this.currentBillingPlans.map((item) => item.max_locations)),
    ].map((item) => ({
      id: item,
      name: item > 0 ? `${item}` : 'без ограничений',
    }))
  }

  get billingPeriodList(): number[] {
    return [
      ...new Set(this.currentBillingPlans.map((item) => item.billing_period)),
    ]
  }

  get billingPlan(): BillingPlanModel {
    return this.currentBillingPlans
      .filter(
        (item) =>
          item.max_locations === this.maxLocations &&
          item.billing_period === this.billingPeriod
      )
      .sort((a, b) => (b.price || 0) - (a.price || 0))[0]
  }

  async created(): Promise<void> {
    this.loading = true
    const [categories, billingPlans] = await Promise.all([
      CategoryService.getCategories(),
      BillingPlanService.getBillingPlans(this.current),
      this.getPlaceTypes(true),
    ])
    this.categories = categories
    this.billingPlans = billingPlans
    this.loading = false
  }

  selectCategory(id: number): void {
    this.categoryId = id
    this.currentBillingPlans = this.billingPlans.filter(
      (item) => item.category === id
    )
    this.maxLocations = this.currentBillingPlans
      .map((item) => item.max_locations)
      .sort((a, b) => a - b)[0]
    this.billingPeriod = this.currentBillingPlans
      .filter((item) => item.max_locations === this.maxLocations)
      .map((item) => item.billing_period)
      .sort((a, b) => a - b)[0]
  }

  updateMaxLocations(val: number): void {
    this.maxLocations = val
    const exactPeriod = this.currentBillingPlans.find(
      (item) =>
        item.max_locations === val && item.billing_period === this.billingPeriod
    )
    if (!exactPeriod) {
      this.billingPeriod = this.currentBillingPlans
        .filter((item) => item.max_locations === val)
        .map((item) => item.billing_period)
        .sort((a, b) => a - b)[0]
    }
  }

  updateBillingPeriod(val: number): void {
    this.billingPeriod = val
    const exactLocations = this.currentBillingPlans.find(
      (item) =>
        item.billing_period === val && item.max_locations === this.maxLocations
    )
    if (!exactLocations) {
      this.maxLocations = this.currentBillingPlans
        .filter((item) => item.billing_period === val)
        .map((item) => item.max_locations)
        .sort((a, b) => a - b)[0]
    }
  }

  async pay(now = false): Promise<void> {
    this.processing = true
    try {
      const result = await BillingPlanService.setBillingPlan(
        this.billingPlan.id,
        this.current
      )
      if (!this.current && now && this.billingPlan.sum) {
        this.$emit('invoice', this.billingPlan.sum)
      } else if (now && this.billingPlan.sum) {
        this.$emit('close')
        await this.$router.push({ query: { tab: 'invoices' } })
      } else {
        this.$emit('close')
      }
    } catch (error) {
      if (BillingPlanService.isServiceError(error)) {
        this.errorMessage = error.response?.data?.message || error.message
      }
      this.errorMessage = this.errorMessage || 'Неизвестная ошибка'
      this.showErrorModal = true
    }
    this.processing = false
  }
}
