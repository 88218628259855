import axios from '@/api/axios'
import { BillingPlanModel } from './models/BillingPlanModel'

class BillingPlanService {
  async getBillingPlans(current = false): Promise<BillingPlanModel[]> {
    try {
      const response = await axios.get(
        `api/profi/v1/billing-plans${current ? '?change=current' : ''}`
      )
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('getBillingPlans', error.message, error.response?.data)
      }
      throw error
    }
  }

  async setBillingPlan(id: number, current = false): Promise<unknown> {
    try {
      const response = await axios.post(
        'api/profi/v1/billing-plans/set',
        current ? { id, change: 'current' } : { id }
      )
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('setBillingPlan', error.message, error.response?.data)
      }
      throw error
    }
  }

  isServiceError = axios.isAxiosError
}

export default new BillingPlanService()
